import { MdCall, MdLocationPin } from 'react-icons/md'
import { CiMail } from 'react-icons/ci'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'

export const Footer = () => {
  return (
    <>
      <div className="w-full h-[500px] bg-header flex items-center justify-evenly px-[20px] mobile:flex-col mobile:items-start mobile:gap-12 mobile:h-full mobile:p-[40px]">
        <div>
          <h1 className="font-jost font-semibold text-[25px] underline-custom ">
            About Us
          </h1>
          <div className=" flex items-center gap-4 font-jost text-[18px] text-gray-300 pt-8">
            <div className="w-[40px] h-[40px] bg-body rounded-md flex items-center justify-center">
              {' '}
              <MdCall size={24} />
            </div>
            +9100000000
          </div>
          <div className=" flex items-center gap-4 font-jost text-[18px] text-gray-300 pt-4">
            <div className="w-[40px] h-[40px] bg-body rounded-md flex items-center justify-center">
              {' '}
              <CiMail size={24} />
            </div>
            example@gmail.com
          </div>
          <div className=" flex items-start gap-4 font-jost text-[18px] text-gray-300 pt-4">
            <div className="w-[40px] h-[40px] bg-body rounded-md flex items-center justify-center">
              {' '}
              <MdLocationPin size={24} />
            </div>
            <p className="font-jost text-[18px] text-gray-300 w-[350px] mobile:w-full">
              {' '}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vitae
              nemo
            </p>
          </div>
        </div>
        <div>
          <h1 className="font-jost font-semibold text-[25px] underline-custom ">
            Our Links
          </h1>
          <div className="flex flex-col gap-1 pt-4 font-jost text-[18px] text-gray-300">
            <Link className="hover:underline" to={'/movies'}>
              Movies
            </Link>
            <Link className="hover:underline" to={'/tv'}>
              TV
            </Link>
            <Link className="hover:underline" to={'/about-us'}>
              About Us
            </Link>
            <Link className="hover:underline" to={'/contact-us'}>
              Contact Us
            </Link>
            <Link className="hover:underline" to={'/terms'}>
              Terms
            </Link>
            <Link className="hover:underline" to={'/policy'}>
              Policy
            </Link>
          </div>
        </div>
        <div>
          <h1 className="font-jost font-semibold text-[25px] underline-custom ">
            Social Medias
          </h1>
          <div className="pt-4 flex flex-col gap-6 mobile:flex-row">
            <div className="w-[40px] h-[40px] rounded-md bg-body items-center justify-center flex cursor-pointer text-[#1877F2] hover:text-white duration-300 hover:bg-[#1877F2] ">
              <FaFacebookF size={24} />
            </div>
            <div className="w-[40px] h-[40px] rounded-md bg-body items-center justify-center flex cursor-pointer text-[#1DA1F2] hover:text-white duration-300 hover:bg-[#1DA1F2]">
              <FaTwitter size={24} />
            </div>
            <div className="w-[40px] h-[40px] rounded-md bg-body items-center justify-center flex cursor-pointer text-[#FD1D1D] hover:text-white duration-300 hover:bg-[#FD1D1D]">
              <FaInstagram size={24} />
            </div>
          </div>
        </div>
      </div>
      <div className=" text-center bg-header pb-4">
        <p className="text-sm opacity-[0.7]">
          <span className="text-lg">&#169;</span> 2024 4k Movies. All Right
          Reserved.
        </p>
      </div>
    </>
  )
}

import { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Navbar = () => {
  const [nav, setNav] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }

  const closeNav = () => {
    setNav(false)
  }

  return (
    <>
      <div className="hidden mobile:block z-10" onClick={handleNav}>
        {!nav ? <FaBars size={24} /> : null}
      </div>

      {/* Mobile Menu */}
      {nav && (
        <div className="md:hidden lg:hidden xl:hidden absolute top-0 left-0 w-screen h-screen bg-header z-[1000] flex flex-col justify-center items-center">
          <ul className="flex flex-col gap-3 items-center">
            <li className="text-white font-bold">
              <Link to="/movies" onClick={closeNav}>
                Movies
              </Link>
            </li>
            <li className="text-white font-bold">
              <Link to="/tv" onClick={closeNav}>
                TV
              </Link>
            </li>
            <li className="text-white font-bold">
              <Link to="/about-us" onClick={closeNav}>
                About us
              </Link>
            </li>
            <li className="text-white font-bold">
              <Link to="/contact-us" onClick={closeNav}>
                Contact us
              </Link>
            </li>
            <li className="text-white font-bold">
              <Link to="/terms" onClick={closeNav}>
                Terms
              </Link>
            </li>
            <li className="text-white font-bold">
              <Link to="/policy" onClick={closeNav}>
                Policy
              </Link>
            </li>
          </ul>

          <div
            onClick={handleNav}
            className="text-white absolute top-7 right-12 cursor-pointer"
          >
            <FaTimes size={24} />
          </div>
        </div>
      )}
    </>
  )
}

export default Navbar
